import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import * as Components from '@p14/components';
import config from '@p14/config';

export const Title = styled.h1`
  margin: 60px auto 0;
  text-align: center;
`;

export const Message = styled.h3`
  text-align: center;
  margin: 30px auto 60px;

  a {
    color: ${config.colors.main};
    text-decoration: none;
  }
`;

export const NotFound = () => (
  <Components.Container>
    <Title>404</Title>
    <Message>К сожалению, такой страницы не существует. Перейдите на <Link to="/">главную</Link>.</Message>
  </Components.Container>
);

export default NotFound;
